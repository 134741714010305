
function Header(props) {
    
    return (
<div className='header'>
 
    </div>  
    )
};

export default Header;