import React from 'react';

function Footer(props) {
    
    return(
        <div className="footer">
<hr/>
<p>
    Copyright &copy; All Rights Reserved Digital Investors Hub 2023 
<br/>
    This Site Was Made By Digital Investors Hub
</p>

</div>
    )
};

export default Footer;
        