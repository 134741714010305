import React from 'react';

function Navi(props) {
  return (
    <div className='nav2'>
      <a href='https://offthecurb.net/collections/all'>
        SHOP NEW CUSTOM APPAREL 
    </a>
    </div>
  )
}

export default Navi;
